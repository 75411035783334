.remediation-actions-table {
    border-collapse: collapse;

    td {
        padding: 4px 0;
    }

    &.header-border {
        tbody {
            outline: 1px solid transparent;
            border: 20px solid transparent;
            border-radius: 4px;
        }

        td {
            padding: 5px;
        }
    }

    .remediation-header,
    .remediation-content {
        td:first-of-type {
            width: 0;
            min-width: fit-content;
            padding-right: 35px;

            p {
                white-space: nowrap;
            }
        }
    }

    .remediation-content {
        td:nth-of-type(2) {
            width: 0;
            min-width: fit-content;

            label {
                padding-right: 35px;
                white-space: nowrap;
            }
        }
    }

    tbody:nth-of-type(1) {
        &.remediation-header {
            outline-color: var(--ui-color-disabledBorder);
        }
    }

    tbody:nth-of-type(2) {
        &.remediation-content {
            td {
                padding-top: 25px;
            }
        }
    }
}

.remediation-footer {
    margin: 50px 9px 9px 9px;
}
