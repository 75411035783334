//---------------------------------------------------------
// Bubbles
//---------------------------------------------------------
.bubble-overflow-visible {
    // Cells with bubbles and info (alarms name) have to override overflow!
    overflow: visible !important;
}

// bug in uilib: theme does not support tippy width
div[id^='tippy-'] {
    > div {
        max-width: 70vw !important;
    }
}

.table-cell {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    white-space: nowrap;

    padding: 5px;
    flex-flow: row nowrap;

    &--centered {
        justify-content: center;
    }

    &--padding {
        padding-left: 20px;
    }

    .table-cell-section {
        flex-wrap: nowrap !important;
        margin-right: 0px !important;
        padding: 0px;
        overflow: hidden;

        .table-cell-tag {
            overflow: visible;

            p {
                white-space: nowrap !important;
            }
        }
    }
}

.action-threat {
    &:hover {
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
        background-color: white !important;

        border: solid 1px var(--esmc-color-bubble-threat);
        color: var(--ui-color-error) !important;
    }
}

.action-warning {
    &:hover {
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
        background-color: white !important;

        border: solid 1px var(--ui-color-warningBig);
        color: var(--ui-color-warning) !important;
    }
}

.action-normal {
    &:hover {
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
        background-color: white !important;

        border: solid 1px var(--ui-color-infoBig);
    }
}

.ei-tooltip {
    height: 100%;
    width: 100%;
    word-break: break-all;
}

.ei-cell-overflow {
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.origin-url-cell {
    display: flex;
    align-items: center;
    &__icon {
        margin-right: 3px;
    }
}

.default-cell-icon {
    min-width: 16px;
}

// We have our own custom context menu, we have to hide the native table context menu in the cell button
.TableRowFrozenPart {
    .contextMenuColumn:empty {
        display: none !important;
    }
}
