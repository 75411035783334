//---------------------------------------------------------
// Global styles used in whole application.
//---------------------------------------------------------

*,
*::before,
*::after {
    box-sizing: border-box;
}

.ei-valign-middle {
    vertical-align: middle;
}

.ei-cursor-pointer {
    cursor: pointer;
}

.ei-bleak-text {
    color: var(--ui-color-disabledBorder);
    font-style: italic;
}

.ei-incomplete-data-text {
    font-style: italic;
    margin-left: 10px;
}

.ei-text-uppercase {
    text-transform: uppercase;
}

.ei-monospace-font {
    font-family: monospace;
}

//---------------------------------------------------------
// EI NORMALIZED LENGTHS
//---------------------------------------------------------
.ei-full-height {
    height: 100%;
}

.ei-flex-full-height {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.ei-full-width {
    width: 100%;
}

.ei-quarter-viewport-width {
    width: 25vw;
}

.ei-responsive-width {
    @media (min-width: 1600px) {
        width: 60%;
    }

    @media (min-width: 1200px) and (max-width: 1600px) {
        width: 80%;
    }

    @media (max-width: 1200px) {
        width: 100%;
    }
}

//---------------------------------------------------------

.clickable {
    cursor: pointer;
}

.nonclickable {
    cursor: default;
}

.hidden {
    display: none;
}

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ei--flex-row {
    display: flex;
}

.bubble {
    display: flex;
    align-items: center;
    border-radius: 5px;
    max-width: 100%;
    padding: 1px 4px !important;
    border: solid 1px transparent;
    line-height: 1em;
    overflow: hidden;
    text-overflow: ellipsis;

    &.normal {
        background-color: #efefef;
    }

    &.warning {
        background-color: var(--ui-color-warningBig);
        color: white;
    }

    &.error {
        background-color: var(--esmc-color-bubble-threat);
        color: white;
    }

    &.threat {
        background-color: var(--esmc-color-bubble-threat);
        color: white;
    }

    &.blue {
        background-color: #78c0e4;
        color: white;
    }

    &.selected {
        border: solid 2px;
        border-color: var(--ui-color-selection);
        border-radius: 4px;
    }

    &.eset {
        background-color: var(--ui-color-turquoise);
        color: white;
    }
}

.ei-white-space-10 {
    margin-left: 10px;
}

.ei-font-bold {
    font-weight: bold;
}

.ei-font-normal {
    font-weight: normal;
}

.ei-header-layer {
    z-index: 9999 !important;
}

.ei-top-layer {
    z-index: 99999;
}

.ei-create-wizard > div > div {
    z-index: 1 !important;
}

.list-no-mark::before {
    display: none !important;
}

.footer-status {
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 4px;
    color: white;
    position: absolute;
    bottom: 50px;
    z-index: 99999;

    &:hover {
        opacity: 0.1;
    }

    &--left {
        left: 50px;
    }

    &--right {
        right: 50px;
    }

    &--normal {
        background-color: var(--esmc-color-table-selection-bubble-background);
    }

    &--warning {
        background-color: var(--ui-color-warningBig);
    }
}

//-----------------------------------------------------------------------------
// UI Library Colors
//
// These are default values just to create proper entries - DO NOT CHANGE THEM!
// During initialization they are updated from the eset-ui-colors module.
//-----------------------------------------------------------------------------
:root {
    --ui-color-success: #77bb1a;
    --ui-color-successBig: #8dc640;
    --ui-color-successBackground: #eef7e3;
    --ui-color-warning: #e9a514;
    --ui-color-warningBig: #fbbf01;
    --ui-color-warningBackground: #fcf2dc;
    --ui-color-error: #e04239;
    --ui-color-errorBackground: #f9e7e6;
    --ui-color-info: #3daae1;
    --ui-color-infoBig: #69b8e0;
    --ui-color-infoBackground: #e1f1f9;
    --ui-color-primary: #3daae1;
    --ui-color-primaryHover: #82bdf4;
    --ui-color-primaryActive: #4690d5;
    --ui-color-primaryBackground: #dfedfa;
    --ui-color-secondary: #fff;
    --ui-color-secondaryHover: #f0f3fb;
    --ui-color-secondaryActive: #dfe4f1;
    --ui-color-disabled: #999;
    --ui-color-disabledBackground: #dfdfdf;
    --ui-color-disabledBorder: #c6c6c6;
    --ui-color-text: #737373;
    --ui-color-iconColor: #576575;
    --ui-color-headlineColor: #424d56;
    --ui-color-sidebarBackground: #363a40;
    --ui-color-sidebarColor: #949ca1;
    --ui-color-turquoise: #0094a2;
    --ui-color-formInputColor: #000000;
    --ui-color-formInputBorderColor: #c6c6c6;
    --ui-color-formInputBackground: #ffffff;
    --ui-color-formInputPlaceholderColor: #bfbfbf;
    --ui-color-toastContainerBackgroundColor: #4c4c4c;
    --ui-color-toastContainerTextColor: #ffffff;
    --ui-color-barColor: #e3e3e3;
    --ui-color-default-text-color: #454545;
    --ui-color-selection: #a5a5a5;

    // Temporary colors taken from ESMC.
    --esmc-color-commanding-background: #f3f3f3;
    --esmc-color-header-filter-background: #42464f;
    --esmc-color-header-filter-border-hover: #6a6d74;
    --esmc-color-header-filter-border: #52555c;
    --esmc-color-table-selection-bubble-background: #45505d;
    --esmc-color-bubble-threat: #cb564f;
}

//-----------------------------------------------------------------------------
// UI-LIBRARY EXTENSIONS
//
// Component team should be notified to implement these features.
//-----------------------------------------------------------------------------
.ui-fix-split-pane {
    // I_UILIB-898 >> SplitPane wrong width / height of second child
    > div {
        flex-direction: column;

        &:last-child {
            width: 100%;
        }
    }
}

.ui-fix-split-pane--no-resizer {
    > div:nth-child(2) {
        display: none;
    }
}

.ui-fix-split-pane--no-resizer.expand-width-after-remove {
    > div:nth-child(1) {
        min-width: 100% !important;
        max-width: 100% !important;
    }
}

.ui-fix-page-big-title {
    padding-top: 9px !important;
    padding-bottom: 9px !important;

    & > div > div > h1 {
        font-weight: 400 !important;
        line-height: 34px !important;
        font-size: 18px !important;
    }
}

.ui-fix-page-headline-title {
    font-weight: normal !important;
    white-space: nowrap;
}

.ui-fix-breadcrumb-content {
    line-height: 16px;
    font-size: 11px;
    font-weight: normal;
}

.ui-fix-link {
    &:hover {
        text-decoration: underline;
    }
}

.ui-fix-page-footer {
    &::before {
        border-bottom: 1px solid var(--ui-color-barColor);
    }
}

// Page header adds underlines to the breadcrumbs which doesn't look nice.
.breadcrumb li span,
a {
    border-bottom-color: transparent !important;
}

.no-link-style {
    text-decoration: none;
    color: unset;
}

.ei-break-word {
    white-space: pre-wrap;
    word-break: break-word;
}

.ei-white-space-preserve {
    white-space: pre-wrap;
    word-break: break-all;
}

.modal-details-list {
    margin: 0;
    padding-left: 15px;
    max-height: 50vh;
    word-break: break-all;
}

.accordions-panel {
    .simple-table {
        padding: 0 10px;
        overflow-y: auto;
        max-height: calc(100vh - 600px);
        min-height: 150px;
    }

    .col {
        height: 90%;
        display: flex;
        align-items: center;
    }

    .executable {
        > * {
            margin-right: 5px;
        }
    }
}

.ei-modal-error-text {
    white-space: pre-wrap;
}

.pre-text {
    flex: 1;
    margin: 10px 0;
    padding: 8px;
    line-height: 1.3;
}

.pre-text--tooltip {
    max-width: 40vw;
    white-space: pre-wrap;
}

.pre-text:not(.pre-text--tooltip) {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow-x: auto;
    white-space: pre;
}

.ei-selectable-text {
    user-select: text;
}

.underlined {
    text-decoration: underline;
}

.cell-text {
    white-space: nowrap !important;
}

.cell-text .inline {
    display: inline;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.hide-overflow {
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}
