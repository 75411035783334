.ei-file-uploader {
    position: relative; /* Now we can set absoute style on FileUpload component. */
}

.ei-file-uploader__core {
    /* Move FileUpload component over dummy button */
    position: absolute;
    top: 0;

    /* Hide style of FileUpload component so the button style is visible. */
    background-color: transparent !important;
    border: none !important;
}

.ei-file-uploader__core > div > div {
    display: none;
}