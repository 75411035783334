.ei-toolbar {
    background-color: var(--esmc-color-commanding-background);
    display: flex;
    justify-content: space-between;

    .toolbar-section-left {
        display: flex;
        flex-grow: 1;
    }

    .toolbar-section-right {
        display: flex;
    }

    .toolbar-buttons-group {
        flex-grow: 1;
    }

    .no-wrap {
        flex-wrap: nowrap;
    }
}

.toolbar-buttons-group {
    display: flex;

    & > :nth-child(2)::after {
        border: none !important;
    }

    & > :nth-child(3) {
        display: flex;
        flex-grow: 1;
        justify-content: right;
    }
}
