.full-page-panel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    overflow: auto;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    transition: opacity 0.3s;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    pointer-events: auto;
    z-index: 5;

    &.full-page-only-label {
        background-color: transparent;
    }

    &.relative-page-panel {
        position: relative !important;
    }

    .full-page-label-container {
        height: 100px;
        position: absolute;
    }
}
