.daterangepicker {
    z-index: 99999;

    > .drp-buttons {
        .btn {
            min-width: 60px;
            border-radius: 4px;
            cursor: pointer;
            padding: 6px;
        }

        // Attach styles of the secondary button.
        .cancelBtn {
            border: 1px solid var(--ui-color-primary);

            background-color: var(--ui-color-secondary);

            &:hover {
                background-color: var(--ui-color-secondaryHover);
            }

            color: var(--ui-color-primary);
        }

        // Attach styles of the primary button.
        .applyBtn {
            border: 1px solid var(--ui-color-primary);

            background-color: var(--ui-color-primary);

            &:hover {
                background-color: var(--ui-color-primaryHover);
            }

            color: var(--ui-color-secondary);
        }
    }
}
