.icon-button {
    pointer-events: none;
}

.icon-button-white {
    background: white;
    border: none;
}

.icon-button-transparent {
    background: transparent;
    border: none;
}

.icon-button-sidebar {
    background: none;
    border: none;
    padding: 0px;
    margin-left: 8px;
    &:disabled {
        cursor: not-allowed;
    }
}
