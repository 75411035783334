.local-filters-select {
    min-width: 140px;

    &.local-filter-users {
        min-width: 200px;
    }

    .select__value-container {
        text-transform: initial !important;
    }
}

.local-filters-text-uppercase {
    .select__value-container {
        text-transform: uppercase;
    }
}

.local-filters-presets-button {
    padding: 4px;
}

// Fixes overlapping UI lib height styles when select is in another select component
.local-filters-select > div .select__value-container .select__multi-value {
    height: 22px !important;
}

.TagContentItem {
    display: flex;
    margin-left: 0px !important;
    gap: 4px;

    .show-subgroups-checkbox {
        margin-left: 5px;
    }
}

.local-filter-plain {
    padding: 7px;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 4px;

    &--active {
        background-color: var(--ui-color-secondary);
        border-color: var(--ui-color-primary);
    }
}

.local-filter-form {
    display: flex;
    align-items: center;
}

.local-filters-hidden {
    height: 52px;

    > div {
        display: none !important;
    }
}

.local-filter-icon {
    border: none;
    background-color: transparent;
}

.local-filter-label {
    text-transform: uppercase;
    white-space: nowrap;
    margin-right: 5px;
}

.local-filter-blank-label {
    font-style: italic;
    text-transform: capitalize;
    text-align: center;
    width: 50px;
}

.local-filters-slider {
    position: relative;

    .rc-slider {
        width: 100px;
        margin: 0 !important;

        .rc-slider-rail {
            border: 1px solid black !important;
            top: 15px !important;
            background-color: black;
        }

        .rc-slider-track {
            background-color: black;
            border: 1px solid black !important;
            top: 15px !important;
        }

        .rc-slider-handle {
            width: 6px !important;
            border: 1px solid black !important;
            background-color: white !important;
            margin-left: -3px !important;
        }
    }

    .local-filters-range {
        position: absolute;
        top: 3px;

        display: flex;
        margin: 0 2px;
        width: 96px;
        justify-content: space-between;
    }

    .local-filters-tick {
        width: 2px;
        height: 8px;
        background-color: black;
    }

    .local-filters-ticks::selection {
        color: unset;
    }
}

.slider-LE-layout {
    .rc-slider-rail {
        background-color: white !important;
    }

    .rc-slider-track {
        background-color: black !important;
    }
}

.slider-GE-layout {
    .rc-slider-rail {
        background-color: black !important;
    }

    .rc-slider-track {
        background-color: white !important;
    }
}

.slider-EQ-layout {
    .rc-slider-rail {
        background-color: white !important;
    }

    .rc-slider-track {
        background-color: white !important;
    }
}

.slider-NE-layout {
    .rc-slider-rail {
        background-color: black !important;
    }

    .rc-slider-track {
        background-color: black !important;
    }
}

.local-filter-time-picker {
    min-width: 60px;
    width: auto;
    color: var(--ui-color-primaryActive);

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}
