.process-icon {
    position: relative;

    &__integrity {
        position: absolute;
    }

    &__breadcrumb {
        bottom: -2px;
    }
}
