.ei-details-list {
    & > ul {
        line-height: normal; // override ui-lib settings to avoid scrollbars on the list
        list-style: none;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 0;
        margin-top: 0;

        & .list-item {
            color: var(--ui-color-default-text-color);
            display: flex;
            flex-direction: row;
            border-top: 1px solid #e7e7e7;
            padding: 10px 5px;

            &:first-child {
                border-top: none;
            }

            & > * {
                align-self: center;
            }

            &.group {
                display: block;
                border: 1px solid #dfdfdf {
                    radius: 4px;
                }
                margin-bottom: 10px;
                padding: 0;

                &:last-child {
                    margin-bottom: 15px;
                }

                &.nested {
                    border: none;
                    border-top: 1px solid #e7e7e7;
                    border-radius: 0;
                }

                .header {
                    color: var(--ui-color-default-text-color);
                    background-color: #eaeaea;
                    display: flex;
                    flex-direction: row;
                    padding: 10px 5px;

                    & > * {
                        align-self: center;
                    }

                    &.toggle {
                        cursor: pointer;
                    }

                    .toggle {
                        background-color: inherit;
                        border: none;
                        padding: 0;

                        &:focus {
                            outline: none;
                        }
                    }

                    &.nested {
                        background-color: unset;
                        padding: 17px 10px 5px 5px;
                    }
                }

                & > ul {
                    padding: {
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }

    .list-label {
        display: inline-block;
        width: calc(50px + 20%);
        margin-right: 15px;

        &-title {
            font-weight: bold;
        }
    }

    .list-value {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        width: calc(70% - 50px);

        & > div {
            display: flex;
            flex-direction: row;
            width: 100%;
            overflow-wrap: break-word;

            & > * {
                align-self: center;
            }
        }
    }

    .subtitle {
        font-size: 16px;
        font-weight: normal;
        padding: 20px;
    }
}
