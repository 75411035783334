.computers-table {
    height: 100%;
    border: 1px solid var(--ui-color-disabledBorder);
}

.learning-mode-settings {
    height: 100%;
    flex-grow: 1;
    flex-wrap: nowrap !important;

    .computers-form-container {
        overflow: hidden;
        height: 100%;

        .computers-form {
            height: 100%;
        }
    }
}

.learning-mode-notification {
    display: flex;

    height: 34px;
    background-color: var(--esmc-color-header-filter-background);
    color: var(--ui-color-info);
    border: solid 1px var(--ui-color-info);
    border-radius: 4px;
    cursor: default;
    padding: 0 10px;
    margin: 7px;

    &:hover {
        border-color: var(--ui-color-infoBig);
        color: var(--ui-color-infoBig);
    }

    &__icon {
        align-self: center;
    }

    &__label {
        align-self: center;
        text-transform: uppercase;
        white-space: nowrap !important;
        margin-left: 10px !important;
    }
}
