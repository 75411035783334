$pane-header-height: 40px;

.ei-complex-header {
    display: flex;
    justify-content: space-between;
    padding-top: 14px;
    padding-bottom: 14px;
}

.pane-header {
    height: $pane-header-height;
    line-height: $pane-header-height;
    display: flex;
    justify-content: space-between;
    background-color: var(--esmc-color-commanding-background);

    &__input {
        width: 100%;
        align-self: center;
    }

    &__icon {
        background-color: var(--esmc-color-commanding-background);
        border: none;
    }
}

.pane-content {
    height: calc(100% - #{$pane-header-height});
}

.pane-content-tags {
    @extend .pane-content;
    overflow: auto;
}
