.ei-panel {
    > .ei-panel-header {
        padding: 0 !important;

        > div {
            padding: 8px !important;
            > :last-child {
                // PageHeader__small -> PageHeader__custom
                width: 100%;
            }
        }
    }
    .ei-panel-content-non-standard-style {
        padding: 0 !important;
        z-index: 0 !important;
    }
}
