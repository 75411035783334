.complex-table-header {
    display: flex;
    align-items: center;
    width: 100%;
    &.header-disabled::after {
        content: '';
        position: absolute;
        background-color: rgba(255, 255, 255, 0.5);
        height: 100%;
        width: 100%;
        z-index: 1;
    }
}
