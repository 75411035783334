//-----------------------------------------------------------------------------
// EI Reputation icons
//-----------------------------------------------------------------------------
@for $i from 0 through 8 {
    .ei-reputation-icon-#{$i + 1} {
        $icon-width: 95px;
        $icon-height: 15px;

        display: inline-block; // allow to use span instead of div which simplifies positioning
        background-repeat: no-repeat;
        background-position: 0 0;
        min-width: $icon-width;
        height: $icon-height !important; // Tiles need !important option.

        background-position-y: -$icon-height * $i;
    }
}

//-----------------------------------------------------------------------------
// EI Popularity icons
//-----------------------------------------------------------------------------
@for $i from 0 through 11 {
    .ei-popularity-icon-#{$i} {
        $icon-width: 115px;
        $icon-height: 15px;

        display: inline-block; // allow to use span instead of div which simplifies positioning
        background-repeat: no-repeat;
        background-position: 0 0;
        min-width: $icon-width;
        height: $icon-height !important; // Tiles need !important option.

        background-position-y: -$icon-height * $i;
    }
}
